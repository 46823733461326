import { render, staticRenderFns } from "./waterMaster10Detail.vue?vue&type=template&id=dc1c1d8c"
import script from "./waterMaster10Detail.vue?vue&type=script&lang=js"
export * from "./waterMaster10Detail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\guyoung\\GuyoungFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dc1c1d8c')) {
      api.createRecord('dc1c1d8c', component.options)
    } else {
      api.reload('dc1c1d8c', component.options)
    }
    module.hot.accept("./waterMaster10Detail.vue?vue&type=template&id=dc1c1d8c", function () {
      api.rerender('dc1c1d8c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/env/water/mst/waterMaster10Detail.vue"
export default component.exports